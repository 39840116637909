import "./EnvelopeBreakout.css";
import { useState, useEffect } from "react";
import currency from "currency.js";
import { deepClone } from "../lib/utils";
import PropTypes from "prop-types";

const EnvelopeBreakout = ({
  i_Envelopes,
  i_TotalAmount, // Amounts should be passed in as whole numbers ($15.99, should be sent in as 1599)
  i_InitialBreakout, // Amounts should be passed in as whole numbers ($15.99, should be sent in as 1599)
  fi_HandleChange,
}) => {
  const [envelopeValues, setEnvelopeValues] = useState([]); // envelopeID, amount (type=currency)
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    let tempArray = deepClone(i_InitialBreakout);
    tempArray = tempArray.map((obj) => ({
      ...obj,
      amount: currency(Math.abs(obj.amount)).divide(100), // This creates a currency object in amount (not a number).
      envelopeID: obj.envelope || obj.envelopeID,
    }));

    // This next step (removing unused fields) isn't necessary, but will avoid major confusion if debugging.
    tempArray.forEach((obj) => {
      delete obj.envelopeName;
      delete obj.envelope;
      delete obj.id;
      delete obj.transactionID;
    });

    setEnvelopeValues(tempArray);
  }, [i_InitialBreakout]);

  useEffect(() => {
    let isMounted = true;

    // Simulate async loading
    const timer = setTimeout(() => {
      if (isMounted) {
        setIsLoaded(true);
      }
    }, 1000);

    // Cleanup function
    return () => {
      isMounted = false;
      clearTimeout(timer);
    };
  }, []);

  useEffect(() => {
    if (isLoaded) {
      setRemainingBalance();
    }
  }, [i_TotalAmount]);

  // Setup the envelope dropdown. Add all of the available Envelope names into the dropdown.
  let envelopesDropdown = i_Envelopes.map((currentEnvelope, index) => (
    <option key={index} value={currentEnvelope.id}>
      {currentEnvelope.envelopeName +
        " (" +
        (currentEnvelope.balance / 100).toLocaleString("en-US", {
          style: "currency",
          currency: "USD",
        }) +
        ")"}
    </option>
  ));

  // Handle an envelope or amount changing.
  let envelopeChange = (index, name, value) => {
    if (name == "amount") {
      value = currency(value);
    }

    if (name == "envelopeID") {
      value = Number(value);
    }

    // Set the internal (EnvelopeBreakout) values.
    let newEnvelopeValues = [...envelopeValues];
    newEnvelopeValues[index][name] = value;
    setEnvelopeValues(newEnvelopeValues); //TODO: Is this even needed?

    // Setup and call the external handleChange routine.
    const externalEnvelopeValues = newEnvelopeValues.map((obj) => ({
      ...obj,
      amount: currency(obj.amount).intValue,
      envelope: obj.envelopeID,
    }));
    fi_HandleChange(externalEnvelopeValues);
  };

  // Add an additional envelope.
  let addFormFields = () => {
    let balance = remainingBalance(true);
    setEnvelopeValues([
      ...envelopeValues,
      { envelopeID: i_Envelopes[0].id, amount: currency(balance.amount) },
    ]);
  };

  // Remove an envelope.
  let removeFormFields = (i) => {
    let newEnvelopeValues = [...envelopeValues];
    newEnvelopeValues.splice(i, 1);
    setEnvelopeValues(newEnvelopeValues);
  };

  // Calculate the Remaining Balance.
  const remainingBalance = (includeLast) => {
    let totalSoFar = 0.0;
    let done = false;
    let index = 0;

    while (!done) {
      // If you've moved beyond the end of the array.
      if (index == envelopeValues.length) {
        done = true;
        index--;
      } else if (
        // If the value is not a number....
        isNaN(envelopeValues[index].amount) || // Amount is Not a Number.
        isNaN(parseFloat(envelopeValues[index].amount)) // Amount is blank. Blank is a number (according to isNaN), but NaN when converted to float.
      ) {
        done = true;
      } else {
        if (index == envelopeValues.length - 1) {
          // If this is the last item in the array...
          done = true;
          if (includeLast) {
            totalSoFar = currency(totalSoFar).add(envelopeValues[index].amount);
          }
        } else {
          // If this is not the last number in the array and you haven't moved beyond the end of the array.
          totalSoFar = currency(totalSoFar).add(envelopeValues[index].amount);
          index++;
        }
      }
    }
    let amountRemaining = currency(i_TotalAmount)
      .divide(100)
      .subtract(totalSoFar);
    return { amount: amountRemaining, index: index };
  };

  const setRemainingBalance = () => {
    let amountRemaining = remainingBalance(false);
    amountRemaining.index > -1 &&
      envelopeChange(amountRemaining.index, "amount", amountRemaining.amount);
  };

  return (
    <div>
      {envelopeValues.map((element, index) => (
        <div className='form-group' key={index}>
          <div className='container'>
            <div className='row align-items-end'>
              <div className='col-6 p-0'>
                {index === 0 && <label htmlFor='envelope'>Category</label>}
                <select
                  name='envelopeID'
                  id={`envelopeID-${index}`}
                  className='form-select'
                  value={element.envelopeID}
                  onChange={(e) =>
                    envelopeChange(index, e.target.name, e.target.value)
                  }>
                  {envelopesDropdown}
                </select>
              </div>

              <div className='col-6 p-0'>
                {index === 0 && <label htmlFor='amount'>Amount</label>}
                <div className='input-group'>
                  <div className='input-group-prepend'>
                    <span className='input-group-text'>$</span>
                  </div>
                  <input
                    type='number'
                    name='amount'
                    id={`amount-${index}`}
                    className='form-control'
                    value={currency(element.amount).value || ""}
                    onChange={(e) =>
                      envelopeChange(
                        index,
                        e.target.name,
                        currency(e.target.value)
                      )
                    }
                    onBlur={(e) => {
                      setRemainingBalance();
                    }}
                  />
                  <div className='input-group-append'>
                    {index ? (
                      <button
                        type='button'
                        className='btn btn-primary'
                        onClick={() => removeFormFields(index)}>
                        -
                      </button>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}

      <div>
        <button
          type='button'
          className='btn btn-primary btn-sm'
          onClick={() => {
            addFormFields();
          }}>
          Add
        </button>
      </div>
    </div>
  );
};

EnvelopeBreakout.propTypes = {
  i_Envelopes: PropTypes.arrayOf(PropTypes.object).isRequired,
  i_TotalAmount: PropTypes.number.isRequired,
  i_InitialBreakout: PropTypes.arrayOf(PropTypes.object).isRequired,
  fi_HandleChange: PropTypes.func.isRequired,
};

export default EnvelopeBreakout;
