import currency from "currency.js";

export const qfxArray = async (qfxFile) => {
  class transaction {
    constructor() {
      this.Type = "TEST";
      this.DatePosted = "";
      this.User = "";
      this.Amount = 0;
      this.FITID = "";
      this.Name = "";
      this.Memo = "";
      this.Matched = false;
    }
  }

  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    // reader.readAsText(qfxFile);
    reader.onload = () => {
      const lines = reader.result.split(/(?=<)/);
      let currentPrefix = "";
      let index = 0;
      let currentYear = "";
      let currentMonth = "";
      let currentDay = "";
      let bankTransaction;
      let tempBankTrans = [];

      lines.forEach((currentLine) => {
        // Load up the line prefix (<DTUSER, MEMO, etc)
        currentPrefix = "";
        index = currentLine.indexOf(">", 0);
        if (index > 0) {
          currentPrefix = currentLine.substring(0, index + 1);
          // Select case of prefix
          switch (currentPrefix) {
            // <STMTTRN> - Beginning of transaction, create a new object.
            case "<STMTTRN>":
              bankTransaction = new transaction();
              break;

            // <TRNTYPE> - DEBIT, CREDIT, etc.
            case "<TRNTYPE>":
              bankTransaction.Type = currentLine.substring(index + 1);
              break;

            // <DTPOSTED> - Date posted
            case "<DTPOSTED>":
              bankTransaction.DatePosted = currentLine.substring(index + 1);
              currentYear = bankTransaction.DatePosted.substring(0, 4);
              currentMonth = bankTransaction.DatePosted.substring(4, 4 + 2);
              currentDay = bankTransaction.DatePosted.substring(6, 6 + 2);
              bankTransaction.DatePosted =
                currentMonth + "/" + currentDay + "/" + currentYear;
              break;

            // <DTUSER> - Bank's user ID
            case "<DTUSER>":
              bankTransaction.User = currentLine.substring(index + 1);
              break;

            // <TRNAMT> - Amount
            case "<TRNAMT>":
              bankTransaction.Amount = currency(
                currentLine.substring(index + 1)
              ).intValue;
              break;

            // <FITID> - Financial Instituion's Transaction ID
            case "<FITID>":
              bankTransaction.FITID = currentLine.substring(index + 1);
              break;

            // <NAME> - Payee / Payer
            case "<NAME>":
              bankTransaction.Name = currentLine.substring(index + 1);
              break;

            // <MEMO> - Memo
            case "<MEMO>":
              bankTransaction.Memo = currentLine.substring(index + 1);
              break;

            // </STMTTRN> - End of transaction, save transaction object to array.
            case "</STMTTRN>":
              tempBankTrans.push(bankTransaction);
              break;

            default:
          }
        }
      });
      resolve(tempBankTrans);
    };

    reader.onerror = (error) => {
      reject(error);
    };

    reader.readAsText(qfxFile);
  });
};
