import Modal from "react-modal";
import { useState, useEffect } from "react";
import currency from "currency.js";

const AddEnvelopeTrans = ({
  i_show,
  i_envelopes,
  i_transaction,
  fi_handleCancel,
  fi_saveEvTransfer,
  fi_deleteTransaction,
}) => {
  const [id, setID] = useState(0); // 0 = new transaction
  const [payee, setPayee] = useState("");
  const [sourceEnvelope, setSourceEnvelope] = useState();
  const [destEnvelope, setDestEnvelope] = useState();
  const [date, setDate] = useState();
  const [amount, setAmount] = useState(currency(0));
  const [note, setNote] = useState("");
  const [showDelete, setShowDelete] = useState(false);

  const IsNull = (value) => value === null || value === undefined;

  useEffect(() => {
    // If this is an edit, load the transaction into the form...
    if (Object.keys(i_transaction).length !== 0) {
      setID(i_transaction[0].transactionID);
      setPayee(i_transaction[0].payee);
      setDate(i_transaction[0].date);
      setAmount(currency(Math.abs(i_transaction[0].amount)).divide(100));

      // Set Envelope values
      i_transaction[1].forEach((envelope) => {
        if (envelope.amount > 0) {
          setDestEnvelope(envelope.envelopeID);
        } else setSourceEnvelope(envelope.envelopeID);
      });

      // Set Note
      if (IsNull(i_transaction[0].note)) {
        setNote("");
      } else {
        setNote(i_transaction[0].note);
      }

      // Show form
      setShowDelete(true);

      // ...otherwise, setup for a new transaction.
    } else {
      setID(0);
      setPayee("");
      let currentDate = new Date().toJSON().slice(0, 10);
      setDate(currentDate);
      setAmount(currency(0));
      setNote("");
      if (i_envelopes !== undefined && Object.keys(i_envelopes).length > 0) {
        setDestEnvelope(i_envelopes[0].id);
        setSourceEnvelope(i_envelopes[0].id);
      }
      setShowDelete(false);
    }
  }, [i_transaction, i_envelopes]);

  // Show or Hide the popup based on the i_show parameter. This line has to be after all useState calls.
  if (!i_show) return null;

  // Setup the envelope dropdowns. Add all of the available Envelope names into the dropdown.
  let envelopesDropdown = i_envelopes.map((currentEnvelope, index) => (
    <option key={index} value={currentEnvelope.id}>
      {currentEnvelope.envelopeName +
        " (" +
        (currentEnvelope.balance / 100).toLocaleString("en-US", {
          style: "currency",
          currency: "USD",
        }) +
        ")"}
    </option>
  ));

  // ** onSubmit
  const onSubmit = (e) => {
    e.preventDefault();

    // Ensure manditory fields have been filled in.
    if (!date || !amount) {
      alert("Manditory Fields not filled in");
      return;
    }

    let stayOpen;
    if (e.nativeEvent.submitter.name === "saveAndNewButton") {
      stayOpen = true;
    } else {
      stayOpen = false;
    }

    const transaction = {
      type: process.env.REACT_APP_Type_EnvTransfer,
      id: id,
      payee: payee,
      date: date,
      totalAmount: currency(amount).intValue,
      sourceEnvelope: sourceEnvelope,
      destEnvelopes: [
        { envelope: destEnvelope, amount: currency(amount).intValue },
      ],
      note: note,
      stayOpen: stayOpen,
    };
    fi_saveEvTransfer(transaction);

    setID(0);
    setPayee("");
    setDate("");
    setNote("");
    setAmount(currency(0));
    setDestEnvelope();
    setSourceEnvelope();
  };

  const deleteTransaction = (e) => {
    e.preventDefault();
    fi_deleteTransaction(id);
  };

  // Set tranfer amount to be the full balance of the FROM Envelope.
  const entireBalance = (e) => {
    e.preventDefault();
    let obj = i_envelopes.find((o) => o.id === Number(sourceEnvelope));
    setAmount(currency(obj.balance).divide(100).value);
  };

  Modal.setAppElement("#root");

  return (
    <Modal
      isOpen={true}
      shouldCloseOnOverlayClick={true}
      onRequestClose={fi_handleCancel}
      style={{
        overlay: {
          backgroundColor: "rgba(0,0,0,0.6)",
        },
        content: {
          margin: "auto",
          width: "40%",
          height: showDelete ? "72%" : "70%",
          backgroundColor: "rgb(246, 241, 235)",
          padding: "20px",
        },
      }}>
      <h1 className='text-center mb-3'>Category Transfer</h1>

      {/* Name */}
      <form className='container-fluid' onSubmit={onSubmit}>
        <div className='row mb-3'>
          <div className='col-md-6'>
            <label htmlFor='name' className='form-label'>
              Name
            </label>
            <input
              type='text'
              id='name'
              className='form-control'
              placeholder='Name (Optional)'
              autoFocus
              value={payee}
              onChange={(e) => setPayee(e.target.value)}
            />
          </div>

          {/* Date */}
          <div className='col-md-6'>
            <label htmlFor='date' className='form-label'>
              Date
            </label>
            <input
              type='date'
              id='date'
              className='form-control'
              placeholder=''
              required
              value={date}
              onChange={(e) => setDate(e.target.value)}
            />
          </div>
        </div>

        <div className='row mb-3'>
          {/* From */}
          <div className='col-md-6'>
            <label htmlFor='source' className='form-label'>
              From...
            </label>
            <select
              id='source'
              className='form-select'
              value={sourceEnvelope}
              onChange={(e) => setSourceEnvelope(e.target.value)}>
              {envelopesDropdown}
            </select>
          </div>
          {/* To */}
          <div className='col-md-6'>
            <label htmlFor='dest' className='form-label'>
              To...
            </label>
            <select
              id='dest'
              className='form-select'
              value={destEnvelope}
              onChange={(e) => setDestEnvelope(e.target.value)}>
              {envelopesDropdown}
            </select>
          </div>
        </div>

        {/* Amount */}
        <div className='row mb-3'>
          <div className='col-md-12'>
            <label htmlFor='amount' className='form-label'>
              Amount
            </label>
            <div className='input-group'>
              <div className='input-group-prepend'>
                <span className='input-group-text'>$</span>
              </div>
              <input
                type='number'
                id='amount'
                className='form-control'
                placeholder=''
                required
                value={currency(amount).value}
                onChange={(e) => setAmount(currency(Math.abs(e.target.value)))}
              />
              <button className='btn btn-secondary' onClick={entireBalance}>
                Set to Entire Balance
              </button>
            </div>
          </div>
        </div>

        {/* Note */}
        <div className='row mb-3'>
          <div className='col-md-12'>
            <label htmlFor='note' className='form-label'>
              Note
            </label>
            <input
              type='text'
              id='note'
              className='form-control'
              placeholder='Note (Optional)'
              value={note}
              onChange={(e) => setNote(e.target.value)}
            />
          </div>
        </div>

        <div className='row mt-4'>
          <div className='col-md-12'>
            <div className='d-grid gap-2'>
              {/* Submit Button */}
              <input
                type='submit'
                className='btn btn-primary'
                value='Save Transaction'
              />

              {/* Save and New button */}
              <input
                type='submit'
                name='saveAndNewButton'
                className='btn btn-primary'
                value='Save + New'
              />

              {/* Cancel Button */}
              <button className='btn btn-secondary' onClick={fi_handleCancel}>
                Cancel
              </button>

              {/* Delete Button */}
              {showDelete && (
                <button className='btn btn-danger' onClick={deleteTransaction}>
                  Delete
                </button>
              )}
            </div>
          </div>
        </div>
      </form>
    </Modal>
  );
};

export default AddEnvelopeTrans;
