import { useDroppable } from "@dnd-kit/core";
import "./BankImport.css";

const EnvelopeDroppable = ({ i_Envelope, fi_handleUnmatchClick }) => {
  const { isOver, setNodeRef } = useDroppable({
    id: "Env:" + i_Envelope.id.toString(), //Very important this be a string, not a number.
  });
  const rowClassName = isOver
    ? "overMatch"
    : i_Envelope.hasNew == true
    ? "match"
    : "";

  return (
    <tr key={i_Envelope.id} ref={setNodeRef} className={rowClassName}>
      <th>{i_Envelope.envelopeName}</th>
      {i_Envelope.hasNew == true ? (
        <th>
          <button onClick={() => fi_handleUnmatchClick(i_Envelope.id)}>
            Unmatch
          </button>
        </th>
      ) : (
        <th></th>
      )}
    </tr>
  );
};

export default EnvelopeDroppable;
