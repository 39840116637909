import SetupEnvelope from "../SetupEnvelope/SetupEnvelope";
import { DndContext, closestCenter } from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { useState, useEffect } from "react";

const SetupEnvelopes = ({
  i_envelopes,
  fi_onDelete,
  fi_editEnvelope,
  fi_reorderEnvelopes,
}) => {
  const [envelopes, setEnvelopes] = useState(
    i_envelopes.filter((envelope) => envelope.type !== 999)
  );

  useEffect(() => {
    // Leave out the Unallocated envelope (type 999).
    setEnvelopes(i_envelopes.filter((envelope) => envelope.type !== 999));
  }, [i_envelopes]);

  const handleDrop = (event) => {
    const { active, over } = event;
    if (active.id !== over.id) {
      const activeIndex = envelopes.findIndex((e) => e.id === active.id);
      const overIndex = envelopes.findIndex((e) => e.id === over.id);
      const newEnvelopes = arrayMove(envelopes, activeIndex, overIndex);
      setEnvelopes(newEnvelopes);
      const newEnvOrder = newEnvelopes.map((item, index) => ({
        dbID: item.id, // The items index (from the database)
        position: index + 1, // The position in the current array.
      }));
      fi_reorderEnvelopes(newEnvOrder);
    }
  };

  return (
    <DndContext collisionDetection={closestCenter} onDragEnd={handleDrop}>
      <table className='table table-striped mt-4'>
        <thead className='thead-light'>
          <tr>
            <th scope='col'>Name</th>
            <th scope='col'>Type</th>
            <th scope='col'>Full Amount</th>
            <th scope='col'>Show Progress Bar</th>
            <th scope='col'>Show Progress Tick</th>
            <th scope='col'>Next Due Date / End Date</th>
            <th scope='col'>Time Frame</th>
            <th scope='col'>Delete</th>
          </tr>
        </thead>
        <tbody>
          <SortableContext
            items={envelopes}
            strategy={verticalListSortingStrategy}>
            {envelopes.map((envelope) => (
              <SetupEnvelope
                key={envelope.id}
                id={envelope.id}
                i_envelope={envelope}
                fi_onDelete={fi_onDelete}
                fi_editEnvelope={fi_editEnvelope}
              />
            ))}
          </SortableContext>
        </tbody>
      </table>
    </DndContext>
  );
};

export default SetupEnvelopes;
