import React from "react";
import "./SmartCategorization_Modal.css";
import Modal from "react-modal";
import MoneyCat from "../../Images/multiple-money-bags-filled-to-varying-amounts- (1).png";

const SmartCategorization_Modal = ({ i_show, fi_handleCancel }) => {
  if (!i_show) return null;
  Modal.setAppElement("#root");
  return (
    <Modal
      isOpen={i_show}
      onRequestClose={fi_handleCancel}
      shouldCloseOnOverlayClick={true}
      className='modal-custom smart-categorization-modal'
      overlayClassName='overlay-custom'>
      <div className='modal-content'>
        <div className='modal-header'>
          <h2 className='modal-title'>Smart Categorization</h2>
          <button onClick={fi_handleCancel} className='close-button'>
            &times;
          </button>
        </div>
        <div className='modal-body'>
          <div className='content-wrapper'>
            <div className='image-container'>
              <img
                src={MoneyCat}
                alt='Money Categories'
                className='responsive-image'
              />
            </div>
            <div className='text-content'>
              <p>
                Easy Breezy Budget empowers you to create custom categories that
                perfectly align with your unique spending habits and financial
                goals. This feature offers a flexible and personalized approach
                to organizing your finances, going beyond what traditional
                budgeting apps provide.
              </p>
              <ul>
                <li>
                  <strong>Adaptability:</strong> As your spending habits evolve,
                  categories can be easily adjusted, added, or removed to keep
                  your budget relevant and effective.
                </li>
                <li>
                  <strong>Granularity:</strong> Create both broad and specific
                  categories, allowing for a detailed breakdown of expenses when
                  needed.
                </li>
                <li>
                  <strong>Customizable Display:</strong> Set up each category to
                  show:
                  <ul>
                    <li>Target/Full Amount</li>
                    <li>Progress Bar indicating how much is left</li>
                    <li>
                      Designation as a spending category (e.g., groceries,
                      entertainment) or a savings category (e.g., vacation,
                      holiday fund)
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className='modal-footer'>
          <button className='btn btn-primary' onClick={fi_handleCancel}>
            Close
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default SmartCategorization_Modal;
