const Pricing = () => {
  let revDate = process.env.REACT_APP_Rev_Date;
  return (
    <div className='container'>
      <div>Pricing</div>
      <div>{revDate}</div>
    </div>
  );
};

export default Pricing;
