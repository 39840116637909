import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { postToAPI } from "../Components/lib/api";
import "./Login.css";

const Login = () => {
  let navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [submitting, setSubmitting] = useState(false);
  const [submittedEmail, setSubmittedEmail] = useState("");
  const [stayLoggedIn, setStayLoggedIn] = useState(false);
  const [errorOccured, setErrorOccured] = useState(false);
  const [errorText, setErrorText] = useState("");
  const strURL_EmailResend = `${process.env.REACT_APP_API_URL}/api/email/resend`;

  const login_API = async (email, password) => {
    let strURL = `${process.env.REACT_APP_API_URL}/api/login`;

    const formData = new FormData();
    formData.append("email", email);
    formData.append("password", password);

    const response = await fetch(strURL, {
      method: "POST",
      headers: {
        Accept: "application/json",
      },
      body: formData,
    });

    setSubmitting(false);
    let data = await response.json();

    if (response.ok) {
      sessionStorage.setItem("token", data.token);
      navigate("/main");
    } else {
      setErrorOccured(true);
      if (response.status === 403) {
        setErrorText(
          data.message + "\nClick here to resend verification email to " + email
        );
      } else {
        setErrorText(data.message);
      }
      console.log("Failed API Call. Status: " + response.status);
      console.log("Failed API Call. StatusText: " + response.statusText);
      console.log("Failed API call. Message: " + data.message);
      console.table(data.errors);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setSubmittedEmail(email);
    setErrorOccured(false);
    setSubmitting(true);
    login_API(email, password);
  };

  const handleResend = async () => {
    const formData = new FormData();
    formData.append("email", submittedEmail);

    const result = await postToAPI("noToken", strURL_EmailResend, formData);

    if (result.status === 200) {
      setErrorText("New email sent!");
    } else if (result.status === 429) {
      setErrorText("Try again later. Only 1 resend per hour.");
    }
  };

  return (
    <div className='login-container'>
      <div className='login-form-container'>
        <h2 className='login-title'>Login to Your Account</h2>
        <form className='login-form' onSubmit={handleSubmit}>
          {submitting && <div className='alert alert-info'>Submitting...</div>}
          {errorOccured && (
            <div className='alert alert-danger'>
              {errorText.split("\n").map((line, index) => (
                <div key={index}>
                  {index === 1 ? (
                    <a href='#' onClick={handleResend}>
                      {line}
                    </a>
                  ) : (
                    line
                  )}
                </div>
              ))}
            </div>
          )}
          <input
            type='email'
            id='emailAddress'
            className='login-input'
            placeholder='Email address'
            required
            autoFocus
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <input
            type='password'
            id='password'
            className='login-input'
            placeholder='Password'
            required
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <div className='login-checkbox hidden'>
            <input
              type='checkbox'
              id='stayLoggedIn'
              checked={stayLoggedIn}
              onChange={(e) => setStayLoggedIn(e.target.checked)}
              disabled
            />
            <label htmlFor='stayLoggedIn'>Stay Logged In</label>
          </div>
          <button type='submit' className='login-button'>
            Login
          </button>
        </form>
        <p className='login-footer'>
          Forgot your password?{" "}
          <a
            href='#'
            onClick={() => alert("This isn't implemented yet. Sorry.")}>
            Click here
          </a>
        </p>
      </div>
      <div className='login-signup'>
        <h2>New here?</h2>
        <p>Sign up to begin controlling your money</p>
        <button onClick={() => navigate("/register")} className='signup-button'>
          Sign Up
        </button>
      </div>
    </div>
  );
};

export default Login;
