import React from "react";
import "./Secure_Modal.css";
import Modal from "react-modal";
import ComputerLock from "../../Images/ComputerLockSM.png";

const Secure_Modal = ({ i_show, fi_handleCancel }) => {
  if (!i_show) return null;
  Modal.setAppElement("#root");
  return (
    <Modal
      isOpen={i_show}
      onRequestClose={fi_handleCancel}
      shouldCloseOnOverlayClick={true}
      className='secure-modal'
      overlayClassName='secure-overlay'>
      <div className='modal-content'>
        <div className='modal-header'>
          <h2>Secure & Private</h2>
          <button onClick={fi_handleCancel} className='close-button'>
            &times;
          </button>
        </div>
        <div className='modal-body'>
          <div className='image-container'>
            <img src={ComputerLock} alt='Secure' className='responsive-image' />
          </div>
          <div className='text-content'>
            <p>
              At Easy Breezy Budget, we understand that your financial data is
              extremely sensitive. That's why we've implemented
              industry-standard security measures to protect your information:
            </p>
            <ul>
              <li>
                <strong>End-to-End Encryption:</strong> All data transmitted
                between your device and our servers is encrypted using
                state-of-the-art protocols.
              </li>
              <li>
                <strong>Secure Data Storage:</strong> We use advanced encryption
                methods to secure your data at rest. Our databases are protected
                by multiple layers of security.
              </li>
              <li>
                <strong>No Data Selling:</strong> We will never sell your
                personal or financial data to third parties.
              </li>
              <li>
                <strong>Minimal Data Collection:</strong> We only collect the
                information necessary to provide our service. You're in control
                of what you share with us.
              </li>
            </ul>
            <p className='highlight-text'>
              Your trust is our most valuable asset, and we're committed to
              maintaining it by keeping your financial data secure and private.
            </p>
          </div>
        </div>
        <div className='modal-footer'>
          <button className='btn btn-primary' onClick={fi_handleCancel}>
            Close
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default Secure_Modal;
