import { useState } from "react";
import { FaTimes, FaEdit, FaBars } from "react-icons/fa";
import currency from "currency.js";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import Modal from "react-modal";
import "./SetupEnvelope.css";

// Set the app element for react-modal
Modal.setAppElement("#root");

const SetupEnvelope = ({ id, i_envelope, fi_onDelete, fi_editEnvelope }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  // Get properties needed for drag n drop.
  const { listeners, setNodeRef, transform, transition } = useSortable({
    id: id,
  });

  const style = { transform: CSS.Transform.toString(transform), transition };

  // Setup the Time Frame (weekly, monthly, etc)
  let timeFrame = "";
  switch (i_envelope.timeFrame) {
    case 1:
      timeFrame = "Weekly";
      break;
    case 2:
      timeFrame = "Monthly";
      break;
    case 3:
      timeFrame = "Quarterly";
      break;
    case 4:
      timeFrame = "Yearly";
      break;
    default:
      timeFrame = "";
  }

  // Setup the Due Date
  let theDate = "";
  if (i_envelope.dueDate !== null) {
    const inputDateStr = i_envelope.dueDate;

    // Parse the input date string
    const parts = inputDateStr.split("-");
    const year = `20${parts[0]}`; // '24' becomes '2024'
    const month = parseInt(parts[1], 10) - 1; // Adjust for 0-indexed months
    const day = parseInt(parts[2], 10);

    // Create a Date object in UTC
    theDate = new Date(Date.UTC(year, month, day, 12));

    // Format the date to a local date string
    const options = { year: "numeric", month: "2-digit", day: "2-digit" };
    const localDateString = theDate.toLocaleDateString("en-US", options);

    theDate = localDateString;
  }

  // Setup Full Amount.
  let fullAmount = "";
  if (i_envelope.fullAmount !== null && i_envelope.fullAmount > 0) {
    fullAmount = currency(i_envelope.fullAmount).divide(100).format();
  }

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleConfirmDelete = () => {
    fi_onDelete(i_envelope.id);
    handleCloseModal();
  };

  return (
    <>
      <Modal
        isOpen={isModalOpen}
        onRequestClose={() => setIsModalOpen(false)}
        contentLabel='Delete Envelope Confirmation'
        className='setup-envelope-modal'
        overlayClassName='setup-envelope-overlay'>
        <h2>Confirm Deletion</h2>
        <p>
          Are you sure you want to delete the envelope "
          {i_envelope.envelopeName}"?
        </p>
        <div>
          <button onClick={handleConfirmDelete}>Yes, Delete</button>
          <button onClick={() => setIsModalOpen(false)}>Cancel</button>
        </div>
      </Modal>
      <tr ref={setNodeRef} style={style}>
        <th>
          <FaBars
            style={{ cursor: "pointer", marginRight: "5px" }}
            {...listeners}
          />
          <FaEdit
            style={{ cursor: "pointer", marginRight: "5px" }}
            onClick={() => fi_editEnvelope(i_envelope)}
          />
          {i_envelope.envelopeName}
        </th>
        <th>{i_envelope.type === 1 ? "Spending" : "Saving"}</th>
        <th>{fullAmount}</th>
        <th>{i_envelope.showProgress === 1 ? "Yes" : "No"}</th>
        <th>{i_envelope.showProgressTick === 1 ? "Yes" : "No"}</th>
        <th>{theDate}</th>
        <th>{timeFrame}</th>
        <th>
          <FaTimes
            style={{ color: "red", cursor: "pointer" }}
            onClick={handleOpenModal}
          />
        </th>
      </tr>
    </>
  );
};

export default SetupEnvelope;
