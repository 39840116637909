import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./Register.css";
import { Tooltip } from "bootstrap";

const Register = () => {
  let navigate = useNavigate();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [emailConfirmed, setEmailConfirmed] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirmed, setPasswordConfirmed] = useState("");
  const [memberType, setMemberType] = useState("Trial");
  const [submitting, setSubmitting] = useState(false);
  const [errorOccured, setErrorOccured] = useState(false);
  const [errorText, setErrorText] = useState("");
  const [sayingToShow] = useState(Math.floor(Math.random() * 11));

  // Add this constant to control the visibility of the Membership Type section
  const showMembershipType = false;

  useEffect(() => {
    // Initialize all tooltips on the page
    const tooltipTriggerList = [].slice.call(
      document.querySelectorAll('[data-bs-toggle="tooltip"]')
    );
    tooltipTriggerList.map((tooltipTriggerEl) => new Tooltip(tooltipTriggerEl));
  }, []);

  const moneySaying = [
    "A nickel ain't worth a dime anymore.",
    "Money never made a man happy yet, nor will it. The more a man has, the more he wants. Instead of filling a vacuum, it makes one.",
    "Formal education will make you a living; self-education will make you a fortune.",
    "Money is only a tool. It will take you wherever you wish, but it will not replace you as the driver.",
    "It is not the man who has too little, but the man who craves more, that is poor.",
    "Happiness is not in the mere possession of money; it lies in the joy of achievement, in the thrill of creative effort.",
    "Money is a terrible master but an excellent servant.",
    "I'm a great believer in luck, and I find the harder I work the more luck I have.",
    "Rich people have small TVs and big libraries, and poor people have small libraries and big TVs.",
    "The habit of saving is itself an education; it fosters every virtue, teaches self-denial, cultivates the sense of order, trains to forethought, and so broadens the mind.",
    "We make a living by what we get, but we make a life by what we give.",
  ];

  const moneyAuthor = [
    "Yogi Berra",
    "Benjamin Franklin",
    "Jim Rohn",
    "Ayn Rand",
    "Seneca",
    "Franklin D. Roosevelt",
    "P.T. Barnum",
    "Thomas Jefferson",
    "Zig Ziglar",
    "T.T. Munger",
    "Winston Churchill",
  ];

  const Register_API = async () => {
    let strURL = `${process.env.REACT_APP_API_URL}/api/register`;
    let language = Number(process.env.REACT_APP_Language_English);
    let currency = Number(process.env.REACT_APP_Currency_USD);

    let accountLevel;
    switch (memberType) {
      case "Trial":
        accountLevel = Number(process.env.REACT_APP_Roles_Trial);
        break;
      case "Monthly":
        accountLevel = Number(process.env.REACT_APP_Roles_Monthly);
        break;
      case "Yearly":
        accountLevel = Number(process.env.REACT_APP_Roles_Yearly);
        break;
      default:
        accountLevel = Number(process.env.REACT_APP_Roles_Trial);
        break;
    }

    const formData = new FormData();
    formData.append("name", name);
    formData.append("email", email);
    formData.append("email_confirmation", emailConfirmed);
    formData.append("password", password);
    formData.append("password_confirmation", passwordConfirmed);
    formData.append("accountLevel", accountLevel);
    formData.append("language", language);
    formData.append("currency", currency);

    try {
      const response = await fetch(strURL, {
        method: "POST",
        headers: {
          Accept: "application/json",
        },
        body: formData,
      });

      if (response.ok) {
        console.log(response.status + " " + response.statusText);
        navigate("/login");
      } else {
        const data = await response.json();
        setErrorOccured(true);
        setErrorText(
          data.message + " " + Object.values(data.errors).join(", ")
        );
        console.log("Failed API Call. Status: " + response.status);
        console.log("Failed API Call. StatusText: " + response.statusText);
        console.log("Failed API call. Message: " + data.message);
        console.table(data.errors);
      }
    } catch (error) {
      console.error("Error during API call:", error);
      setErrorOccured(true);
      setErrorText("An unexpected error occurred. Please try again.");
    }
  };

  const validateForm = () => {
    if (name.trim() === "") {
      setErrorText("Please enter your name.");
      return false;
    }
    if (email !== emailConfirmed) {
      setErrorText("Email addresses do not match.");
      return false;
    }
    if (password !== passwordConfirmed) {
      setErrorText("Passwords do not match.");
      return false;
    }
    if (password.length < 8) {
      setErrorText("Password must be at least 8 characters long.");
      return false;
    }
    return true;
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setErrorOccured(false);
    if (validateForm()) {
      setSubmitting(true);
      Register_API();
      setSubmitting(false);
    } else {
      setErrorOccured(true);
    }
  };

  return (
    <div className='container-fluid register-container'>
      <div className='row vh-100'>
        <div className='col-md-6 register-main d-flex flex-column justify-content-center'>
          <div className='register-form-container'>
            <h1 className='register-title'>Register For An Account</h1>

            {submitting && (
              <div className='alert alert-info'>Submitting Form...</div>
            )}
            {errorOccured && (
              <div
                className='alert alert-danger alert-dismissible fade show'
                role='alert'>
                {errorText}
              </div>
            )}

            <form className='register-form' onSubmit={handleSubmit}>
              <input
                type='text'
                id='name'
                className='form-control'
                placeholder='Your name (e.g., Tom, Sally, Supermom, Budgeteer)'
                required
                autoFocus
                value={name}
                onChange={(e) => setName(e.target.value)}
              />

              <input
                type='email'
                id='email'
                className='form-control'
                placeholder='Email address'
                required
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />

              <input
                type='email'
                id='emailConfirmation'
                className='form-control'
                placeholder='Confirm email address'
                required
                value={emailConfirmed}
                onChange={(e) => setEmailConfirmed(e.target.value)}
              />

              <input
                type='password'
                id='password'
                className='form-control'
                placeholder='Password'
                required
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />

              <input
                type='password'
                id='passwordConfirmation'
                className='form-control'
                placeholder='Confirm password'
                required
                value={passwordConfirmed}
                onChange={(e) => setPasswordConfirmed(e.target.value)}
              />

              {showMembershipType && (
                <div className='mb-3'>
                  <label htmlFor='membershipType' className='form-label'>
                    Membership Type
                  </label>
                  <select
                    className='form-select'
                    id='membershipType'
                    disabled
                    value={memberType}
                    onChange={(e) => setMemberType(e.target.value)}
                    data-bs-toggle='tooltip'
                    data-bs-placement='top'
                    title="Only 'Trial' is available&#10;currently">
                    <option value='Trial'>Free Trial</option>
                    <option value='Monthly'>Monthly</option>
                    <option value='Yearly'>Yearly</option>
                  </select>
                </div>
              )}

              <button
                type='submit'
                className='btn btn-primary btn-lg btn-block register-button'
                disabled={submitting}>
                {submitting ? "Registering..." : "Register"}
              </button>
            </form>
            <p className='mt-3'>
              Already have an account? Sign in{" "}
              <a
                href='#'
                className='register-link'
                onClick={(e) => {
                  e.preventDefault();
                  navigate("/login");
                }}>
                here
              </a>
            </p>
          </div>
        </div>
        <div className='col-md-6 register-secondary d-flex flex-column justify-content-center'>
          <div className='money-quote'>
            <p className='money-saying'>{moneySaying[sayingToShow]}</p>
            <p className='money-author'>- {moneyAuthor[sayingToShow]}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Register;
