import React from "react";
import "./RiskFree_Modal.css";
import Modal from "react-modal";
import FreeTrial from "../../Images/FreeTrialSM.png";

const RiskFree_Modal = ({ i_show, fi_handleCancel }) => {
  if (!i_show) return null;
  Modal.setAppElement("#root");
  return (
    <Modal
      isOpen={i_show}
      onRequestClose={fi_handleCancel}
      shouldCloseOnOverlayClick={true}
      className='risk-free-modal'
      overlayClassName='risk-free-overlay'>
      <div className='modal-content'>
        <div className='modal-header'>
          <h2>Risk-Free Trial</h2>
          <button onClick={fi_handleCancel} className='close-button'>
            &times;
          </button>
        </div>
        <div className='modal-body'>
          <div className='image-container'>
            <img
              src={FreeTrial}
              alt='Free Trial'
              className='responsive-image'
            />
          </div>
          <div className='text-content'>
            <p>
              Easy Breezy Budget offers you a chance to try out our budgeting
              solution with complete peace of mind:
            </p>
            <ul>
              <li>
                <strong>30-Day Free Trial:</strong> Experience all features
                without any commitment.
              </li>
              <li>
                <strong>No Credit Card Required:</strong> Start your trial
                without entering payment information.
              </li>
              <li>
                <strong>Full Feature Access:</strong> Explore every aspect of
                Easy Breezy Budget during your trial.
              </li>
              <li>
                <strong>Easy Transition:</strong> Seamlessly upgrade to a paid
                plan if you love the experience.
              </li>
            </ul>
            <p className='highlight-text'>
              Whether you're new to budgeting or looking for a better solution,
              Easy Breezy Budget is the perfect choice for anyone looking to
              take control of their finances, risk-free.
            </p>
          </div>
        </div>
        <div className='modal-footer'>
          <button className='btn btn-primary' onClick={fi_handleCancel}>
            Close
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default RiskFree_Modal;
