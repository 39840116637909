import { useState, useEffect } from "react";
import Modal from "react-modal";
import currency from "currency.js";
import Envelope from "./../Envelope/Envelope";
import "./AddEnvelope.css";

const AddEnvelope = ({
  i_show,
  i_envelope,
  fi_handleCancel,
  fi_AddEnvelope,
}) => {
  const [envelopeID, setEnvelopeID] = useState(0);
  const [name, setName] = useState("");
  const [position, setPosition] = useState("");
  const [type, setType] = useState(1);
  const [fullAmount, setFullAmount] = useState(0);
  const [showProgress, setShowProgress] = useState(true); // Default to true for new envelopes
  const [showProgressTick, setShowProgressTick] = useState(false);
  const [dueDate, setDueDate] = useState("");
  const [timeFrame, setTimeFrame] = useState(1);

  const IsNull = (value) => value === null || value === undefined;

  const nextFriday = () => {
    let d = new Date();
    let daysTill = (5 + (7 - d.getDay())) % 7;
    if (daysTill === 0) {
      daysTill = daysTill + 7;
    }
    d.setDate(d.getDate() + daysTill);
    return d;
  };

  useEffect(() => {
    if (Object.keys(i_envelope).length !== 0) {
      setEnvelopeID(i_envelope.id);
      setName(i_envelope.envelopeName);
      setType(IsNull(i_envelope.type) ? 1 : i_envelope.type);
      setTimeFrame(IsNull(i_envelope.timeFrame) ? 1 : i_envelope.timeFrame);
      setShowProgress(
        IsNull(i_envelope.showProgress)
          ? false
          : Boolean(i_envelope.showProgress)
      );
      setShowProgressTick(
        IsNull(i_envelope.showProgressTick)
          ? false
          : Boolean(i_envelope.showProgressTick)
      );
      setDueDate(IsNull(i_envelope.dueDate) ? "" : i_envelope.dueDate);
      setFullAmount(
        IsNull(i_envelope.fullAmount)
          ? ""
          : currency(i_envelope.fullAmount).divide(100)
      );
      setPosition(IsNull(i_envelope.position) ? 0 : i_envelope.position);
    } else {
      setEnvelopeID(0);
      setName("");
      setPosition(0);
      setType(1);
      setFullAmount(0);
      setShowProgress(true);
      setShowProgressTick(false);
      setDueDate(nextFriday().toJSON().slice(0, 10));
      setTimeFrame(1);
    }
  }, [i_show, i_envelope]);

  const handleSubmit = (e, isSaveAndNew, shiftPressed) => {
    e.preventDefault();

    if (!name || !type) {
      alert("Mandatory Fields not filled in");
      return;
    }

    const envelope = {
      envelopeID: envelopeID,
      envelopeName: name,
      position: position,
      type: type,
      fullAmount: currency(fullAmount).intValue,
      showProgress: showProgress ? 1 : 0,
      showProgressTick: showProgressTick ? 1 : 0,
      dueDate: dueDate,
      timeFrame: timeFrame,
      stayOpen: isSaveAndNew,
    };

    fi_AddEnvelope(envelope);

    if (isSaveAndNew) {
      setEnvelopeID(0);
      setName("");
      setPosition(0);
      shiftPressed && setType(1);
      setFullAmount(0);
      shiftPressed && setShowProgress(true);
      shiftPressed && setShowProgressTick(false);
      shiftPressed && setDueDate(nextFriday().toJSON().slice(0, 10));
      shiftPressed && setTimeFrame(1);
    }
  };

  if (!i_show) return null;

  Modal.setAppElement("#root");

  return (
    <Modal
      isOpen={i_show}
      onRequestClose={fi_handleCancel}
      shouldCloseOnOverlayClick={true}
      className='add-envelope-modal'
      overlayClassName='add-envelope-overlay'>
      <div className='add-envelope-container'>
        <h2 className='modal-title'>Category</h2>
        <form onSubmit={(e) => handleSubmit(e, false, false)}>
          {/* Name */}
          <div className='form-group'>
            <label htmlFor='name'>Name</label>
            <input
              type='text'
              id='name'
              maxLength={30}
              className='form-control'
              placeholder='Enter category name'
              required
              autoFocus
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </div>

          {/* Type */}
          <div className='form-group'>
            <label>Type</label>
            <div className='radio-group'>
              <label>
                <input
                  type='radio'
                  name='envelopeType'
                  value={1}
                  checked={type === 1}
                  onChange={() => setType(1)}
                />
                Spending
              </label>
              <label>
                <input
                  type='radio'
                  name='envelopeType'
                  value={2}
                  checked={type === 2}
                  onChange={() => setType(2)}
                />
                Savings
              </label>
            </div>
          </div>

          {/* Show Full Bar */}
          <div className='form-group checkbox-group'>
            <label>
              Show Full Bar?
              <input
                type='checkbox'
                checked={showProgress}
                onChange={(e) => setShowProgress(e.target.checked)}
              />
              <span className='checkmark'></span>
            </label>
          </div>

          {/* Full Amount */}
          <div className='form-group'>
            <label htmlFor='fullAmount'>Full Amount</label>
            <div className='input-group'>
              <span className='input-group-text'>$</span>
              <input
                type='number'
                id='fullAmount'
                className='form-control'
                placeholder='Enter full amount'
                disabled={!showProgress}
                value={fullAmount}
                onChange={(e) => setFullAmount(e.target.value)}
              />
            </div>
          </div>

          {/* Show Progress Tickmark */}
          <div className='form-group checkbox-group'>
            <label>
              Show Progress Tickmark?
              <input
                type='checkbox'
                checked={showProgressTick}
                onChange={(e) => setShowProgressTick(e.target.checked)}
              />
              <span className='checkmark'></span>
            </label>
          </div>

          {/* Next Due Date / End Date */}
          <div className='form-group'>
            <label htmlFor='dueDate'>Next Due Date / End Date</label>
            <input
              type='date'
              id='dueDate'
              className='form-control'
              disabled={!showProgressTick}
              value={dueDate}
              onChange={(e) => setDueDate(e.target.value)}
            />
          </div>

          {/* Frequency */}
          <div className='form-group'>
            <label>Frequency</label>
            <div className='radio-group'>
              {["Weekly", "Monthly", "Quarterly", "Yearly"].map(
                (freq, index) => (
                  <label key={freq}>
                    <input
                      type='radio'
                      name='envelopeFreq'
                      disabled={!showProgressTick}
                      value={index + 1}
                      checked={timeFrame === index + 1}
                      onChange={() => setTimeFrame(index + 1)}
                    />
                    {freq}
                  </label>
                )
              )}
            </div>
          </div>

          {/* Preview */}
          <div className='preview-container'>
            <label>Preview (at 75% full):</label>
            <Envelope
              i_envelope={{
                envelopeName: name,
                fullAmount: fullAmount * 100,
                showProgress: showProgress ? 1 : 0,
                showProgressTick: showProgressTick ? 1 : 0,
                balance: fullAmount * 100 * 0.75,
                dueDate: dueDate,
                timeFrame: timeFrame,
                type: type,
              }}
              fi_selectEnvelope={() => {}}
            />
          </div>

          {/* Buttons (Save, Save + New, Cancel) */}
          <div className='button-group'>
            <button type='submit' className='btn-primary'>
              Save
            </button>
            <button
              type='button'
              className='btn-secondary'
              data-toggle='tooltip'
              dta-placement='top'
              title='Shift+Click to reset all data'
              onClick={(e) => handleSubmit(e, true, e.shiftKey)}>
              Save + New
            </button>
            <button
              type='button'
              className='btn-cancel'
              onClick={fi_handleCancel}>
              Cancel
            </button>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default AddEnvelope;
