import Modal from "react-modal";
import { useState, useEffect } from "react";
import "./FillEnvelopes.css";
import currency from "currency.js";

const FillEnvelopes = ({
  i_show,
  i_envelopes,
  i_transaction,
  fi_saveFill,
  fi_deleteTransaction,
  fi_handleCancel,
}) => {
  const [id, setID] = useState(0); // 0 = new transaction
  const [totalAmount, setTotalAmount] = useState(currency(0));
  const [payer, setPayer] = useState("");
  const [date, setDate] = useState();
  const [note, setNote] = useState();
  const [deposit, setDeposit] = useState(true);
  const [showDelete, setShowDelete] = useState(false);
  const [envelopeValues, setEnvelopeValues] = useState([]);
  const [amountFunded, setAmountFunded] = useState(currency(0));
  const [originalUnallocated, setOriginalUnallocated] = useState([]);

  // Function to determine if value is Null. Returns True or False
  const IsNull = (value) => value === null || value === undefined;

  // Setup the envelopeValues array.
  useEffect(() => {
    let tempArray = [];
    let tempOriginalUnallocated;
    let unallocatedID;

    // Setting up the list of envelopes that will be shown on the screen. This is every envelope the user has.
    i_envelopes.forEach((envelope) => {
      // Add the non-Unallocated envelopes to a holding array (tempArray) which will be set to envelopeValues (later in this procedure).
      if (envelope.type != 999) {
        tempArray.push({
          envelopeName: envelope.envelopeName,
          envelope: envelope.id,
          amount: currency(0),
          type: envelope.type,
          fullAmount: currency(envelope.fullAmount).divide(100).format(),
          balance: currency(envelope.balance).divide(100).format(),
        });
      }
      // ...and save the UnallocatedID. Make a copy of the Unallocated envelope for use later in this procedure.
      else {
        unallocatedID = envelope.id;
        tempOriginalUnallocated = structuredClone(envelope);
      }
    });

    // If a transaction is passed in (for editing), load the transactions values on the screen...
    if (Object.keys(i_transaction).length !== 0) {
      let index;
      setID(i_transaction[0].transactionID);
      setPayer(i_transaction[0].payee);
      setTotalAmount(currency(i_transaction[0].transaction_Total).divide(100));
      setDate(i_transaction[0].date);
      setDeposit(i_transaction[0].type == 4);

      // Set Note
      if (IsNull(i_transaction[0].note)) {
        setNote("");
      } else {
        setNote(i_transaction[0].note);
      }

      // Load the transaction's envelope amounts into envelopeValues. There is a record for each envelope that made up the transaction.
      i_transaction[1].forEach((currentTrans) => {
        if (currentTrans.envelopeID != unallocatedID) {
          index = tempArray.findIndex(
            (obj) => obj.envelope == currentTrans.envelopeID
          );
          tempArray[index].amount = currency(currentTrans.amount).divide(100);
          tempArray[index].balance = currency(
            tempArray[index].balance
          ).subtract(currency(currentTrans.amount).divide(100));
        }
      });

      // Determing & save the amount that has been set for each envelope (amountFunded).
      let fundedTotal = tempArray.reduce(
        (total, currentValue) =>
          (total = currency(total).add(currentValue.amount)),
        0
      );
      setAmountFunded(currency(fundedTotal));

      tempOriginalUnallocated.balance = currency(
        tempOriginalUnallocated.balance
      )
        .divide(100)
        .add(fundedTotal);

      setOriginalUnallocated(tempOriginalUnallocated);

      setShowDelete(true);

      // ...else setup to create a new transaction.
    } else {
      tempArray.forEach((currentTrans) => {
        currentTrans.amount = currency(0);
      });
      setID(0);
      setPayer("");
      setTotalAmount(currency(0));
      setAmountFunded(currency(0));
      let currentDate = new Date().toJSON().slice(0, 10);
      setDate(currentDate);
      setNote("");
      setDeposit(true);
      if (!IsNull(tempOriginalUnallocated)) {
        tempOriginalUnallocated.balance = currency(
          tempOriginalUnallocated.balance
        ).divide(100);
      }
      setOriginalUnallocated(tempOriginalUnallocated);
      setShowDelete(false);
    }

    setEnvelopeValues(tempArray);
  }, [i_transaction, i_envelopes]);

  // Handle an envelope amount changing.
  let envelopeChange = (index, name, value) => {
    // Handle blank value being passed in.
    if (value === "") {
      value = currency(0);
    }

    // Update envelopeValues with the new amount for the given envelope.
    let newenvelopeValues = [...envelopeValues];
    newenvelopeValues[index][name] = value;
    setEnvelopeValues(newenvelopeValues);

    // Update the amountFunded (by adding up all of the amounts funded).
    let fundedTotal = envelopeValues.reduce(
      (total, currentValue) =>
        (total = currency(total).add(currentValue.amount)),
      0
    );
    setAmountFunded(fundedTotal);
  };

  // onSubmit
  const onSubmit = (e) => {
    e.preventDefault();

    // Ensure manditory fields have been filled in.
    if (deposit) {
      if (!payer || !date || !totalAmount) {
        alert("Manditory Fields not filled in");
        return;
      }
    } else {
      if (!date) {
        alert("Manditory Fields not filled in");
        return;
      }
    }

    // Create transaction to be saved.
    let unallocatedAmount;
    let transactionType;
    let totalAmountToSave;
    let unallocatedToSave;
    let envelopesToSave;
    let sourceEnvelope = "";

    // If this is a deposit...
    if (deposit) {
      transactionType = process.env.REACT_APP_Type_DepositFill;
      unallocatedAmount = currency(totalAmount).subtract(amountFunded).intValue;
      totalAmountToSave = currency(totalAmount).intValue;
      if (unallocatedAmount > 0) {
        unallocatedToSave = {
          envelopeName: originalUnallocated.envelopeName,
          envelope: originalUnallocated.id,
          amount: unallocatedAmount,
          type: originalUnallocated.type,
          fullAmount: originalUnallocated.fullAmount,
          balance: originalUnallocated.balance,
        };
        envelopesToSave = envelopeValues.filter((n) => n.amount > 0);
        envelopesToSave.forEach((element) => {
          element.amount = currency(element.amount).intValue;
        });
        envelopesToSave = envelopesToSave.concat(unallocatedToSave);
      } else {
        envelopesToSave = envelopeValues.filter((n) => n.amount > 0);
        envelopesToSave.forEach((element) => {
          element.amount = currency(element.amount).intValue;
        });
      }

      // ...else this is a fill from Unallocated.
    } else {
      transactionType = process.env.REACT_APP_Type_UnallocatedFill;
      unallocatedAmount = currency(amountFunded).multiply(-1).intValue;
      totalAmountToSave = currency(amountFunded).intValue;
      sourceEnvelope = originalUnallocated.id;
      envelopesToSave = envelopeValues.filter((n) => n.amount > 0);
      envelopesToSave.forEach((element) => {
        element.amount = currency(element.amount).intValue;
      });
    }

    const transaction = {
      id: id,
      payee: payer,
      date: date,
      totalAmount: totalAmountToSave,
      sourceEnvelope: sourceEnvelope,
      destEnvelopes: envelopesToSave,
      checkNum: "",
      note: note,
      type: transactionType,
    };

    // Call save function.
    fi_saveFill(transaction);

    // Clear form values?
  };

  const deleteTransaction = (e) => {
    e.preventDefault();
    fi_deleteTransaction(id);
  };

  Modal.setAppElement("#root");

  return (
    <Modal
      isOpen={i_show}
      onRequestClose={fi_handleCancel}
      shouldCloseOnOverlayClick={true}
      style={{
        overlay: { backgroundColor: "rgba(0,0,0,0.6)" },
        content: {
          margin: "auto",
          width: "75%",
          height: "95%",
          backgroundColor: "rgb(246, 241, 235)",
        },
      }}>
      <div className='container w-80'>
        <h1 className='mb-3'>Fund Categories</h1>
        <form className='m-3'>
          <div>
            {/* New Deposit or From Unallocated */}
            <div
              className='btn-group'
              role='group'
              aria-label='Debit or Credit'>
              <input
                type='radio'
                className='btn-check'
                name='btnradio'
                id='btnDebit'
                disabled={showDelete}
                autoComplete='off'
                checked={deposit == true}
                onChange={() => setDeposit(true)}></input>
              <label className='btn btn-outline-primary' htmlFor='btnDebit'>
                From New Deposit
              </label>
              <input
                type='radio'
                className='btn-check'
                name='btnradio'
                id='btnCredit'
                disabled={showDelete}
                autoComplete='off'
                checked={deposit == false}
                onChange={() => setDeposit(false)}></input>
              <label className='btn btn-outline-primary' htmlFor='btnCredit'>
                From Unallocated
              </label>
            </div>
          </div>

          {/* Payer */}
          <div className='form-group mt-3'>
            <label htmlFor='payer' className='fs-6'>
              {deposit ? "Payer" : "Name (optional)"}
            </label>
            <input
              type='text'
              id='payer'
              className='form-control'
              required={deposit}
              value={payer}
              onChange={(e) => setPayer(e.target.value)}
              autoFocus
            />
          </div>

          {/* Total Amount */}
          {deposit && (
            <div className='form-group mt-3 mb-3'>
              <label htmlFor='amount' className='fs-6'>
                Total Amount
              </label>
              <div className='input-group'>
                <div className='input-group-text'>$</div>
                <input
                  type='number'
                  id='amount'
                  className='form-control'
                  required
                  value={totalAmount}
                  onChange={(e) => setTotalAmount(e.target.value)}
                  onBlur={(e) =>
                    setTotalAmount(parseFloat(e.target.value).toFixed(2))
                  }
                />
              </div>
            </div>
          )}

          {/* Date */}
          <div className='form-group mt-3'>
            <label htmlFor='date' className='fs-6'>
              Date
            </label>
            <input
              type='date'
              id='date'
              className='form-control'
              required
              value={date}
              onChange={(e) => setDate(e.target.value)}
            />
          </div>

          {/* Note */}
          <div className='form-group mt-3'>
            <label htmlFor='note' className='fs-6'>
              Note (Optional)
            </label>
            <input
              type='text'
              id='note'
              className='form-control'
              value={note}
              onChange={(e) => setNote(e.target.value)}
            />
          </div>

          {/* Table */}
          <table className='table mt-4'>
            <thead>
              <tr>
                <th scope='col'> Category </th>
                <th scope='col'> Full Amount </th>
                <th scope='col'> Current Balance </th>
                <th scope='col'> Amount to Add </th>
                <th scope='col'> New Balance </th>
              </tr>
            </thead>
            <tbody>
              {envelopeValues.map((item, index) => (
                <tr key={item.envelopeName}>
                  {/* Envelope Name */}
                  <td scope='row'> {item.envelopeName} </td>
                  {/* Full Amount */}
                  <td> {currency(item.fullAmount).format()} </td>
                  {/* Current Balance  */}
                  <td> {currency(item.balance).format()} </td>
                  {/* Amount to Add (User editable) */}
                  <td>
                    <div className='input-group'>
                      <div className='input-group-prepend'>
                        <span className='input-group-text'>$</span>
                      </div>
                      <input
                        type='number'
                        name='amount'
                        className='form-control'
                        value={item.amount}
                        onChange={(e) =>
                          envelopeChange(index, e.target.name, e.target.value)
                        }
                        onBlur={(e) =>
                          envelopeChange(
                            index,
                            e.target.name,
                            parseFloat(e.target.value).toFixed(2)
                          )
                        }
                      />
                    </div>
                  </td>
                  {/* New Balance */}
                  <td>{currency(item.balance).add(item.amount).format()}</td>
                </tr>
              ))}
            </tbody>
          </table>
          <div>Total Funded = {currency(amountFunded).format()}</div>
          <div>
            {deposit
              ? "Amount to Unallocated = " +
                currency(totalAmount).subtract(amountFunded).format()
              : "Remaining in Unallocated = " +
                currency(originalUnallocated.balance)
                  .subtract(amountFunded)
                  .format()}
          </div>
          <div className='d-grid gap-2 mt-4'>
            {/* Submit Button */}
            <input
              type='submit'
              className='btn btn-primary btn-block'
              value='Save Transaction'
              onClick={onSubmit}
            />

            {/* Delete Button */}
            {showDelete && (
              <button
                className='btn btn-danger btn-block'
                onClick={deleteTransaction}>
                Delete
              </button>
            )}
            {/* Cancel Button */}
            <button
              className='btn btn-secondary btn-block'
              onClick={fi_handleCancel}>
              Cancel
            </button>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default FillEnvelopes;
