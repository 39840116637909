import React from "react";

const Supporters = () => {
  return (
    <div>
      {/* Header */}
      <nav className='navbar navbar-expand-md bg-dark navbar-dark py-3 fixed-top'>
        <div className='container'>
          <a href='/' className='navbar-brand'>
            Easy Breezy Budget
          </a>

          <button
            className='button navbar-toggler'
            type='button'
            data-bs-toggle='collapse'
            data-bs-target='#navmenu'>
            <span className='navbar-toggler-icon'></span>
          </button>

          <div className='collapse navbar-collapse' id='navmenu'>
            <ul className='navbar-nav ms-auto'>
              <li className='nav-item'>
                <a href='/login' className='nav-link'>
                  Sign In
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      {/* Body  */}
      <section className='p-5'>
        <div className='container mt-5'>
          <div className='card'>
            <h5 className='card-header'>12/3/2022</h5>
            <div className='card-body'>
              <h5 className='card-title'>Working on core features</h5>
              <div className='card-text'>
                <p>Hi and welcome to Easy Breezy Budget!</p>
                <p>
                  We're in the pre-beta phase right now, but feel free to look
                  around and try it out. Realize though that until we move to
                  beta testing the data you enter <i>may</i> be deleted as
                  changes to the database are implemented.
                </p>
                <p>
                  Since the last update I've changed from breezy-budget.com to
                  EasyBreezyBudget.com, created social media accounts for
                  EasyBreezyBudget, and corrected bugs related to editing a
                  category transfer.{" "}
                </p>
                Here are the items that have been implemented at this point:
                <ul className='entry-content'>
                  <li>Create an account.</li>
                  <li>Log In.</li>
                  <li>Create Categories.</li>
                  <li>Edit an existing Envelope.</li>
                  <li>Delete a Category.</li>
                  <li>
                    Enter Transactions, allocating the transaction to one or
                    more Categories.
                  </li>
                  <li>Edit an existing Transaction. </li>
                  <li>Delete a Transaction.</li>
                  <li>
                    Click on a Category to see the transactions for that
                    category.
                  </li>
                  <li>
                    Replace 'Envelope' with 'Category', as Category was the
                    overwhelming favorite in the survey. <b>New This Update</b>
                  </li>
                  <li>
                    Show running balance on each transaction when looking at a
                    Category's transactions.
                    <b> New This Update</b>
                  </li>
                </ul>
                Here are some of the items I'm working on:
                <ul>
                  <li>
                    Redo/Improve the look and feel of the website. Implement the
                    winning color scheme from our survey.
                  </li>
                  <li>Allow ordering/grouping of Categories.</li>
                  <li>
                    Allow importing of bank transactions from bank download
                    file.
                  </li>
                  <li>
                    Create new user onboarding features, including picking
                    Categories from a list of common Categories & a screen to
                    set initial values of Categories.
                  </li>
                  <li>Guiding a new user through the process.</li>
                  <li>Implement email verification.</li>
                  <li>Implement reset password functionality.</li>
                </ul>
              </div>
            </div>
          </div>
          <div className='card'>
            <h5 className='card-header'>11/22/2022</h5>
            <div className='card-body'>
              <h5 className='card-title'>Pre-Beta Testing Version</h5>
              <div className='card-text'>
                <p>Hi!</p>
                <p>
                  Hey - thanks for checking out this super early version of Easy
                  Breezy Budget. As you'll see, only the core functionality
                  exists at this time, but I'm working to add more functionality
                  each week + squashing any bugs I find along the way. I'm
                  planning on releasing a new version each Saturday night
                  beginning 12/3/22.
                </p>
                <p>
                  Feel free to look around and try it out. Realize though that
                  until we move to beta testing the data you enter <i>may</i> be
                  deleted as changes to the database are implemented.
                </p>
                Here are the items that have been implemented at this point:
                <ul className='entry-content'>
                  <li>Create an account.</li>
                  <li>Log In.</li>
                  <li>Create Categories.</li>
                  <li>Edit an existing Envelope.</li>
                  <li>Delete a Category.</li>
                  <li>
                    Enter Transactions, allocating the transaction to one or
                    more Categories.
                  </li>
                  <li>Edit an existing Transaction. </li>
                  <li>Delete a Transaction.</li>
                  <li>
                    Click on a Category to see the transactions for that
                    category.
                  </li>
                </ul>
                Here are some of the items I'm working on:
                <ul>
                  <li>
                    Redo/Improve the look and feel of the website. Implement the
                    winning color scheme from our survey.
                  </li>
                  <li>
                    Replace 'Envelope' with 'Category', as Category was the
                    overwhelming favorite in the survey.
                  </li>
                  <li>Allow ordering/grouping of Categories.</li>
                  <li>
                    Allow importing of bank transactions from bank download
                    file.
                  </li>
                  <li>
                    Create new user onboarding features, including picking
                    Categories from a list of common Categories & a screen to
                    set initial values of Categories.
                  </li>
                  <li>Guiding a new user through the process.</li>
                  <li>Show running balance on each transaction.</li>
                  <li>Implement email verification.</li>
                  <li>Implement reset password functionality.</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Footer */}
      <footer className='p-5 bg-dark text-white text-center position-relative'>
        <div className='container'>
          <p className='lead'>&copy; 2024 1034Enterprises</p>
          <a href='#' className='position-absolute bottom-0 end-0 p-5'>
            <i className='bi bi-arrow-up-circle h1'></i>
          </a>
        </div>
      </footer>
    </div>
  );
};

export default Supporters;
