import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import Landing from "./Pages/Landing";
import Login from "./Pages/Login";
import Register from "./Pages/Register";
import Main from "./Pages/Main";
import Envelope from "./Pages/Envelope";
import Supporters from "./Pages/Supporters";
import Pricing from "./Pages/Pricing";
import FreeTools from "./Pages/FreeTools";
import Imports from "./Pages/Imports";
import Error from "./Pages/Error";
import BankImport from "./Pages/BankImport/BankImport";
import PrivacyPolicy from "./Pages/PrivacyPolicy/PrivacyPolicy";
import Terms from "./Pages/Terms/Terms";

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path='/' element={<Landing />} />
        <Route path='/login' element={<Login />} />
        <Route path='/register' element={<Register />} />
        <Route path='/main' element={<Main />} />
        <Route path='/envelope' element={<Envelope />} />
        <Route path='/bankImport' element={<BankImport />} />
        <Route path='/supporters' element={<Supporters />} />
        <Route path='/imports' element={<Imports />} />
        <Route path='/pricing' element={<Pricing />} />
        <Route path='/freetools' element={<FreeTools />} />
        <Route
          path='/privacypolicy/privacypolicy'
          element={<PrivacyPolicy />}
        />
        <Route path='/terms/terms' element={<Terms />} />

        <Route path='*' element={<Error />} />
      </Routes>
    </Router>
  );
};

export default App;
