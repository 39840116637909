import "./FreeImports_Modal.css";
import Modal from "react-modal";
import PersonalizedGuidance from "../../Images/split-screen--customer-service-helping-user-with-c.png";

const FreeImports_Modal = ({ i_show, fi_handleCancel }) => {
  if (!i_show) return null;
  Modal.setAppElement("#root");
  return (
    <Modal
      isOpen={i_show}
      onRequestClose={fi_handleCancel}
      shouldCloseOnOverlayClick={true}
      className='free-imports-modal'
      overlayClassName='free-imports-overlay'>
      <div className='modal-content'>
        <div className='modal-header'>
          <h2>Personalized Setup Guidance</h2>
          <button onClick={fi_handleCancel} className='close-button'>
            &times;
          </button>
        </div>
        <div className='modal-body'>
          <div className='image-container'>
            <img
              src={PersonalizedGuidance}
              alt='Personalized Guidance'
              className='responsive-image'
            />
          </div>
          <div className='text-content'>
            <p>
              At Easy Breezy Budget, we understand that getting started with a
              new budgeting system can be daunting. That's why we offer
              personalized, one-on-one guidance to help you set up your budget
              perfectly.
            </p>
            <div className='guidance-benefits'>
              <h3>Benefits of Our Personalized Setup:</h3>
              <ul>
                <li>Free online meeting with a member of our support team </li>
                <li>Screen sharing for step-by-step guidance</li>
              </ul>
            </div>
            <p>Our team will walk you through:</p>
            <ul>
              <li>Setting up your account</li>
              <li>Creating custom categories that fit your lifestyle</li>
              <li>Inputting your initial financial data</li>
              <li>Understanding key features of Easy Breezy Budget</li>
            </ul>
            <p className='highlight-text'>
              Experience the Easy Breezy Budget difference - where your
              financial journey begins with personalized attention and expert
              guidance.
            </p>
          </div>
        </div>
        <div className='modal-footer'>
          <button className='btn btn-primary' onClick={fi_handleCancel}>
            Close
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default FreeImports_Modal;
