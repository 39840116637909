import React from "react";
import currency from "currency.js";
import { useDraggable } from "@dnd-kit/core";

const BankTransDraggable = ({ i_bankTran, fi_onHide }, props) => {
  const { attributes, listeners, setNodeRef } = useDraggable({
    id: i_bankTran.FITID.toString(),
  });

  const handleHideClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    fi_onHide(i_bankTran.FITID);
  };

  return (
    <tr key={i_bankTran.transactionID}>
      <td
        ref={setNodeRef}
        {...attributes}
        {...listeners}
        style={{ cursor: "move" }}>
        ☰
      </td>
      <td>{i_bankTran.DatePosted}</td>
      <td>{i_bankTran.Name}</td>
      <td>{currency(i_bankTran.Amount).divide(100).format()}</td>
      <td>
        <button onClick={handleHideClick} className='btn btn-sm btn-secondary'>
          Hide
        </button>
      </td>
    </tr>
  );
};

export default BankTransDraggable;
