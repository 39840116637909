import SetupEnvelopes from "../Components/SetupEnvelopes/SetupEnvelopes";
import AddEnvelope from "../Components/AddEnvelope/AddEnvelope";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getFromAPI } from "../Components/lib/api";

const Envelope = () => {
  let navigate = useNavigate();
  const [envelopes, setEnvelopes] = useState([]);
  const [showAddEnvelope, setShowAddEnvelope] = useState(false);
  const [envelope, setEnvelope] = useState([]);

  const strURL_API = `${process.env.REACT_APP_API_URL}/api`;
  const strURL_Envelopes = `${process.env.REACT_APP_API_URL}/api/envelope`;
  const theToken = sessionStorage.getItem("token");

  // ** FUNCTION: Get envelopes from database.
  const getEnvelopes = async () => {
    const envelopesFromServer = await getFromAPI(theToken, strURL_Envelopes);
    setEnvelopes(envelopesFromServer);
  };

  // ** FUNCTION (API): Add an envelope to the database.
  const addEnvelopeToDB = async (envelope) => {
    let strURL = "";
    let strMethod = "";
    if (envelope.envelopeID > 0) {
      strURL = `${strURL_Envelopes}/${envelope.envelopeID}`;
      strMethod = "PUT";
    } else {
      strURL = `${strURL_Envelopes}`;
      strMethod = "POST";
    }

    const formData = new URLSearchParams();
    formData.append("envelopeName", envelope.envelopeName);
    formData.append("position", envelope.position);
    formData.append("type", envelope.type);
    formData.append("fullAmount", envelope.fullAmount);
    formData.append("showProgress", envelope.showProgress);
    formData.append("showProgressTick", envelope.showProgressTick);
    formData.append("dueDate", envelope.dueDate);
    formData.append("timeFrame", envelope.timeFrame);

    const response = await fetch(strURL, {
      method: strMethod,
      headers: {
        Accept: "application/json",
        authorization: "Bearer " + theToken,
        Content_Type: "application/x-www-form-urlencoded",
      },
      body: formData,
    });

    if (response.ok) {
      console.log(response.status + " " + response.statusText);
    } else {
      const data = await response.json();
      console.log("Failed API Call. Status: " + response.status);
      console.log(
        "Failed API Call. StatusText: " + Object.values(response.statusText)
      );
      console.log(
        "Save Tranaction | Failed API call. Message: " + data.message
      );
      console.table(data.errors);
    }
  };

  // ** FUNCTION: Sent to component: Add an envelope.
  const addEnvelope = async (envelope) => {
    await addEnvelopeToDB(envelope);
    if (envelope.stayOpen === false) {
      setShowAddEnvelope(false);
    }
    getEnvelopes();
  };

  // ** FUNCTION (API): Delete the specified envelope from the database.
  const deleteEnvelopeFromDB = async (id) => {
    const strURL = `${strURL_Envelopes}/${id}`;
    const response = await fetch(strURL, {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        authorization: "Bearer " + theToken,
        Content_Type: "application/x-www-form-urlencoded",
      },
    });
    const data = await response.json();
    return data;
  };

  // ** FUNCTION: Sent to component: Delete Envelope
  const deleteEnvelope = async (id) => {
    const deletedEnvelope = await deleteEnvelopeFromDB(id);
    getEnvelopes();
  };

  // ** FUNCTION (API): Reorder the envelopes / Update their Position fields.
  const reorderEnvelopesInDB = async (newEnvelopeOrder) => {
    const formData = new URLSearchParams();
    formData.append("envelopePositions", JSON.stringify(newEnvelopeOrder));
    const strURL = `${strURL_API}/envelopeReorder`;
    const response = await fetch(strURL, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        authorization: "Bearer " + theToken,
        Content_Type: "application/x-www-form-urlencoded",
      },
      body: formData,
      json: true,
    });
    if (response.ok) {
      console.log(response.status + " " + response.statusText);
    } else {
      const data = await response.json();
      console.log("Failed API Call. Status: " + response.status);
      console.log(
        "Failed API Call. StatusText: " + Object.values(response.statusText)
      );
      console.log(
        "Save Tranaction | Failed API call. Message: " + data.message
      );
      console.table(data.errors);
    }
  };

  // ** FUNCTION: Sent to component: process the position/order of the envepes changing.
  const reorderEnvelopes = async (newEnvelopeOrder) => {
    const reorderedEnvelopes = await reorderEnvelopesInDB(newEnvelopeOrder);
  };

  // ** FUNCTION: Button: Show/Hide the Add Envelope portion of the screen.
  const showAdd_Click = () => {
    setEnvelope([]);
    setShowAddEnvelope(true);
  };

  // ** FUNCTION: Button: Return to main screen.
  const returnToMain = () => {
    navigate("/main");
  };

  // ** FUNCTION: Sent to component: Edit an envelope.
  const editEnvelope = (envelope) => {
    setEnvelope(envelope);
    setShowAddEnvelope(true);
  };

  // USEEFFECT (onStartup)
  useEffect(() => {
    getEnvelopes();
  }, []);

  return (
    <div className='container'>
      {/* Modal for adding/editing envelopes */}
      <AddEnvelope
        i_show={showAddEnvelope}
        i_envelope={envelope}
        fi_AddEnvelope={addEnvelope}
        fi_handleCancel={() => {
          setShowAddEnvelope(false);
        }}
      />

      {/* Button to return to main screen. */}
      <button className='btn btn-primary m-2' onClick={returnToMain}>
        Return to Main Screen
      </button>
      {/* Button to show the add/edit envelope modal */}
      <button className='btn btn-primary m-2' onClick={showAdd_Click}>
        Add Category
      </button>

      {/* The listing of existing envelopes */}
      <SetupEnvelopes
        i_envelopes={envelopes}
        fi_onDelete={deleteEnvelope}
        fi_editEnvelope={editEnvelope}
        fi_reorderEnvelopes={reorderEnvelopes}
      />
    </div>
  );
};

export default Envelope;
