// Percentage of the way through the current timeFrame.
export const timeFramePercentage = (timeFrame, dueDate, type) => {
  const {
    isFuture,
    addWeeks,
    addMonths,
    addQuarters,
    addYears,
    parseISO,
    differenceInDays,
    subMonths,
    subQuarters,
    subYears,
  } = require("date-fns");

  // Must use a DATE type in the date.fns functions below. Not a string type (as passed in).
  dueDate = parseISO(dueDate);

  // If dueDate is null return 0%
  if (!(dueDate instanceof Date) || isNaN(dueDate)) {
    return 0;
  }

  // ** Determine when the next due date is? The dueDate passed in was A due date, but not necessarily the next one.
  while (!isFuture(dueDate)) {
    switch (timeFrame) {
      case 1: // Week
        dueDate = addWeeks(dueDate, 1);
        break;
      case 2: // Month
        dueDate = addMonths(dueDate, 1);
        break;
      case 3: // Quarter
        dueDate = addQuarters(dueDate, 1);
        break;
      case 4: // Year
        dueDate = addYears(dueDate, 1);
        break;
      default:
        dueDate = addYears(dueDate, 1);
        break;
    }
  }

  // ** Determine how many days until the next due date (from today).
  const rightNow = new Date();
  const daysTillDue = differenceInDays(dueDate, rightNow);

  // ** Calculate the percentage of time left in this timeFrame (days till due date / total number of days in the specified timeFrame).
  let percentRemaining = 0.0;
  let daysInPeriod = 0;

  switch (timeFrame) {
    case 1: // Week
      daysInPeriod = 7;
      break;
    case 2: // Month
      daysInPeriod = differenceInDays(dueDate, subMonths(dueDate, 1));
      break;
    case 3: // Quarter
      daysInPeriod = differenceInDays(dueDate, subQuarters(dueDate, 1));
      break;
    case 4: // Year
      daysInPeriod = differenceInDays(dueDate, subYears(dueDate, 1));
      break;
    default:
      daysInPeriod = 7;
      break;
  }

  percentRemaining = (daysTillDue + 1) / daysInPeriod;
  percentRemaining *= 100;
  if (type == process.env.REACT_APP_Envelope_Saving) {
    percentRemaining = 100.0 - percentRemaining;
  }

  return percentRemaining;
};

export const amountPercentage = (balance, fullAmount) => {
  let percentRemaining = 0;

  percentRemaining = balance / fullAmount;
  percentRemaining *= 100;

  return percentRemaining;
};

export const deepClone = (obj) => {
  if (Array.isArray(obj)) {
    // If it's an array, recursively clone each element
    return obj.map(deepClone);
  } else if (obj !== null && typeof obj === "object") {
    // If it's an object, recursively clone each property
    return Object.fromEntries(
      Object.entries(obj).map(([key, value]) => [key, deepClone(value)])
    );
  } else {
    // If it's a primitive value, just return it
    return obj;
  }
};

export const dateForAPI = (someDate) => {
  const myDate = new Date(someDate);
  const isoDateString = myDate.toISOString();
  const apiDateString = isoDateString.split("T")[0];
  return apiDateString;
};
