import Transaction from "./../Transaction/Transaction";
import Spinner from "react-bootstrap/Spinner";

const Transactions = ({
  i_transactions,
  fi_openEdit,
  i_showMessage = true,
  loading,
}) => {
  return (
    <>
      {loading ? (
        <div className='text-center'>
          <Spinner animation='border' role='status'>
            <span className='visually-hidden'>Loading...</span>
          </Spinner>
        </div>
      ) : (
        <>
          {!i_transactions.length && i_showMessage && (
            <p>
              <strong>
                *** Step 2: Click on the 'Fill Categories' button above, then
                fill your categories using the current balance of your bank
                account.
              </strong>
            </p>
          )}
          {i_transactions.map((transaction) => (
            <Transaction
              key={transaction.transactionID}
              i_transaction={transaction}
              fi_openEdit={fi_openEdit}
            />
          ))}
        </>
      )}
    </>
  );
};

export default Transactions;
