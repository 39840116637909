// ** Perform a GET from API server.
export const getFromAPI = async (theToken, strURL) => {
  const response = await fetch(strURL, {
    method: "GET",
    headers: {
      Accept: "application/json",
      authorization: "Bearer " + theToken,
    },
  });
  const data = await response.json();
  return data;
};

// ** Perform a PUT to the API server.
export const putToAPI = async (theToken, strURL, formData) => {
  const response = await fetch(strURL, {
    method: "PUT",
    headers: {
      Accept: "application/json",
      authorization: "Bearer " + theToken,
    },
    body: formData,
    json: true,
  });
  if (response.ok) {
    console.log(response.status + " " + response.statusText);
  } else {
    const data = await response.json();
    console.log("Failed API Call. Status: " + response.status);
    console.log(
      "Failed API Call. StatusText: " + Object.values(response.statusText)
    );
    console.log("Save Tranaction | Failed API call. Message: " + data.message);
    console.table(data.errors);
  }
};

// ** Perform a POST to the API server.
export const postToAPI = async (theToken, strURL, formData) => {
  const response = await fetch(strURL, {
    method: "POST",
    headers: {
      Accept: "application/json",
      authorization: "Bearer " + theToken,
    },
    body: formData,
  });

  const data = await response.json();
  if (response.ok) {
    console.log(response.status + " " + response.statusText);
  } else {
    console.log("Failed API Call. Status: " + response.status);
    console.log(
      "Failed API Call. StatusText: " + Object.values(response.statusText)
    );
    console.log("Failed API call. Message: " + data.message);
    console.table(data.errors);
  }

  const responseData = {
    status: response.status,
    text: data.message,
  };

  return responseData;
};

// ** Perform a DELETE to the API Server.
export const deleteTransFromAPI = async (theToken, strURL) => {
  const response = await fetch(strURL, {
    method: "DELETE",
    headers: {
      Accept: "application/json",
      authorization: "Bearer " + theToken,
    },
  });
  if (response.ok) {
    console.log(response.status + " : " + response.statusText);
  } else {
    const data = await response.json();
    // setErrorText(data.message + " " + Object.values(data.errors));
    console.log("Failed API Call. Status: " + response.status);
    console.log(
      "Failed API Call. StatusText: " + Object.values(response.statusText)
    );
    console.log(
      "Delete Transaction | Failed API call. Message: " + data.message
    );
    console.table(data.errors);
  }
};
