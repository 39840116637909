import Modal from "react-modal";
import { useState, useEffect } from "react";
import "./AddTransaction.css";
import EnvelopeBreakout from "../EnvelopeBreakout/EnvelopeBreakout";
import currency from "currency.js";
import PropTypes from "prop-types";

const AddTransaction = ({
  i_show,
  i_envelopes,
  i_transaction,
  fi_saveTransaction,
  fi_deleteTransaction,
  fi_handleCancel,
}) => {
  const [id, setID] = useState(0); // 0 = new transaction
  const [payee, setPayee] = useState("");
  const [date, setDate] = useState();
  const [totalAmount, setTotalAmount] = useState(currency(0));
  const [checkNum, setCheckNum] = useState();
  const [note, setNote] = useState();
  const [FITID, setFITID] = useState("");
  const [envelopeValues, setEnvelopeValues] = useState([]);
  const [showDelete, setShowDelete] = useState(false);
  const [showSaveNew, setShowSaveNew] = useState(true);
  const [debit, setDebit] = useState(false);

  const IsNull = (value) => value === null || value === undefined;

  useEffect(() => {
    if (i_transaction && Object.keys(i_transaction).length !== 0) {
      console.log(
        "i_transaction in AddTransaction:",
        JSON.stringify(i_transaction, null, 2)
      );
    }
  }, [i_transaction]);

  // If this is an edit, load the transaction into the form.
  useEffect(() => {
    // If a transaction has been passed in for Editing...
    if (Object.keys(i_transaction).length !== 0) {
      // Set ID, Payee, Date, Total Amount
      setID(i_transaction[0].transactionID);
      setPayee(i_transaction[0].payee);
      setDate(i_transaction[0].date);
      setTotalAmount(
        currency(Math.abs(i_transaction[0].transaction_Total)).divide(100)
      );
      i_transaction[0].transaction_Total < 0 ? setDebit(true) : setDebit(false);

      // Set Check Number
      if (IsNull(i_transaction[0].checkNumber)) {
        setCheckNum("");
      } else {
        setCheckNum(i_transaction[0].checkNumber);
      }

      // Set Note
      if (IsNull(i_transaction[0].note)) {
        setNote("");
      } else {
        setNote(i_transaction[0].note);
      }

      // Set FITID
      if (IsNull(i_transaction[0].FITID)) {
        setFITID("");
      } else {
        setFITID(i_transaction[0].FITID);
      }

      // Set Envelope values
      let tempArray = [];
      i_transaction[1].forEach((envelope) => {
        tempArray.push({
          envelope: envelope.envelopeID,
          amount: Math.abs(envelope.amount),
        });
      });
      setEnvelopeValues(tempArray);

      // Show delete button
      if (i_transaction[0].transactionID > 0) {
        setShowDelete(true);
        setShowSaveNew(true);
      } else {
        setShowDelete(false);
        setShowSaveNew(false);
      }

      // Else setup for a new transaction to be entered...
    } else {
      setID(0);
      setPayee("");
      let currentDate = new Date().toJSON().slice(0, 10);
      setDate(currentDate);
      setCheckNum("");
      setNote("");
      setDebit(true);
      setTotalAmount(currency(0));
      setFITID("");
      if (i_envelopes !== undefined && Object.keys(i_envelopes).length > 0) {
        setEnvelopeValues([{ envelope: i_envelopes[0].id, amount: 0 }]);
      } else {
      }
      setShowDelete(false);
      setShowSaveNew(true);
    }
  }, [i_transaction, i_envelopes]);

  // Show or Hide the popup based on the i_show parameter. This line has to be after all useState calls.
  if (!i_show) return null;

  // ** onSubmit
  const onSubmit = (e) => {
    e.preventDefault();

    // Ensure mandatory fields have been filled in.
    if (!payee || !date || !totalAmount || !envelopeValues) {
      alert("Mandatory Fields not filled in");
      return;
    }

    let stayOpen;
    const submitter = e.nativeEvent.submitter;
    if (submitter && submitter.name === "saveAndNewButton") {
      stayOpen = true;
    } else {
      stayOpen = false;
    }

    // Setup the Total Amount to be saved.
    let saveTotalAmount;
    debit
      ? (saveTotalAmount = currency(totalAmount).multiply(-1).intValue)
      : (saveTotalAmount = currency(totalAmount).intValue);

    // Ensure envelope totals = transaction total.
    let envTotal = 0;
    envelopeValues.forEach((currentEnv) => {
      envTotal += debit ? currentEnv.amount * -1 : currentEnv.amount;
    });
    if (envTotal !== saveTotalAmount) {
      alert(
        "Transaction total does NOT equal Category amounts. " +
          +saveTotalAmount +
          " <> " +
          envTotal
      );
      return;
    }

    // Setup the Envelope breakdown of the transaction. Could be 1 envelope, could be more than 1 envelope.
    let saveEnvelopeValues = envelopeValues.map((item) => {
      const container = {};
      container.envelope = item.envelope;
      debit
        ? (container.amount = item.amount * -1)
        : (container.amount = item.amount);
      return container;
    });

    const transaction = {
      id: id,
      payee: payee,
      date: date,
      totalAmount: saveTotalAmount,
      destEnvelopes: saveEnvelopeValues,
      checkNum: checkNum,
      note: note,
      type: process.env.REACT_APP_Type_Transaction,
      FITID: FITID,
      stayOpen: stayOpen,
    };
    console.log(
      "Full transaction object to be saved:",
      JSON.stringify(transaction, null, 2)
    );

    fi_saveTransaction(transaction);

    setID(0);
    setPayee("");
    let currentDate = new Date().toJSON().slice(0, 10);
    setDate(currentDate);
    setCheckNum("");
    setNote("");
    setTotalAmount(currency(0));
    setFITID("");
    setEnvelopeValues([{ envelope: i_envelopes[0].id, amount: 0 }]);
  };

  const deleteTransaction = (e) => {
    e.preventDefault();
    fi_deleteTransaction(id);
  };

  Modal.setAppElement("#root");

  return (
    <Modal
      isOpen={i_show}
      onRequestClose={fi_handleCancel}
      shouldCloseOnOverlayClick={true}
      className='add-transaction-modal'
      overlayClassName='add-transaction-overlay'>
      <div className='add-transaction-container'>
        <form onSubmit={onSubmit}>
          <h1 className='modal-title'>Transaction</h1>

          {/* Payee */}
          <div className='form-group'>
            <label htmlFor='payee'>Payee</label>
            <input
              type='text'
              placeholder='Payee'
              id='payee'
              required
              autoFocus
              value={payee}
              onChange={(e) => setPayee(e.target.value)}
            />
          </div>

          {/* Date */}
          <div className='form-group'>
            <label htmlFor='date'>Date</label>
            <input
              type='date'
              placeholder='Date (mm/dd/yyyy)'
              id='date'
              required
              value={date}
              onChange={(e) => setDate(e.target.value)}
            />
          </div>

          {/* Total Amount */}
          <div className='form-group'>
            <label htmlFor='amount'>Total Amount</label>
            <div className='amount-input-group'>
              <div className='amount-input-wrapper'>
                <span className='currency-symbol'>$</span>
                <input
                  type='number'
                  placeholder='Total Amount'
                  id='amount'
                  required
                  value={currency(totalAmount).value}
                  onChange={(e) => {
                    setTotalAmount(currency(Math.abs(e.target.value)));
                  }}
                  className={!debit ? "credit" : ""}
                />
              </div>
              <div className='debit-credit-toggle'>
                <input
                  type='radio'
                  id='btnDebit'
                  name='debitCredit'
                  checked={debit === true}
                  onChange={() => setDebit(true)}
                />
                <label htmlFor='btnDebit'>Debit</label>
                <input
                  type='radio'
                  id='btnCredit'
                  name='debitCredit'
                  checked={debit === false}
                  onChange={() => setDebit(false)}
                />
                <label htmlFor='btnCredit'>Credit</label>
              </div>
            </div>
          </div>

          {/* Envelopes & Amounts */}
          <EnvelopeBreakout
            i_Envelopes={i_envelopes}
            i_TotalAmount={currency(totalAmount).intValue}
            i_InitialBreakout={envelopeValues}
            fi_HandleChange={(newEnvelopeValues) => {
              setEnvelopeValues((prevEnvelopeValues) => [...newEnvelopeValues]);
            }}
          />

          {/* Check Number */}
          <div className='form-group mt-2'>
            <label htmlFor='check'>Check Number</label>
            <input
              type='text'
              placeholder='Check Number (Optional)'
              id='check'
              value={checkNum}
              onChange={(e) => setCheckNum(e.target.value)}
              maxLength={15}
            />
          </div>

          {/* Note */}
          <div className='form-group'>
            <label htmlFor='note'>Note</label>
            <input
              type='text'
              placeholder='Note (Optional)'
              id='note'
              value={note}
              onChange={(e) => setNote(e.target.value)}
            />
          </div>

          <div className='button-group'>
            <button type='submit' name='saveButton' className='btn-primary'>
              Save Transaction
            </button>
            {showSaveNew && (
              <button
                type='submit'
                name='saveAndNewButton'
                className='btn-secondary'>
                Save + New
              </button>
            )}
            {showDelete && (
              <button
                type='button'
                className='btn-danger'
                onClick={deleteTransaction}>
                Delete
              </button>
            )}
            <button
              type='button'
              className='btn-cancel'
              onClick={fi_handleCancel}>
              Cancel
            </button>
          </div>
        </form>
      </div>
    </Modal>
  );
};

AddTransaction.propTypes = {
  i_show: PropTypes.bool.isRequired,
  i_envelopes: PropTypes.arrayOf(PropTypes.object).isRequired,
  fi_saveTransaction: PropTypes.func.isRequired,
  fi_deleteTransaction: PropTypes.func,
  fi_handleCancel: PropTypes.func.isRequired,
};

export default AddTransaction;
